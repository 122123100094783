import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from 'react';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

import { useCompany } from 'hooks/useCompany';
import { Company } from 'interfaces/company';

import { localPayPeriodIntervalForSemiMonthly } from 'utils/dateHelper';

// extend moment
const moment = extendMoment(Moment);

// NOTE: called payPeriodSelection due to pay period being a function of date selection
export interface StepChange {
  operation: string;
  amount: number;
  unit: Moment.unitOfTime.DurationConstructor;
}

interface CalculatedPayPeriod {
  currentStartDate: moment.Moment;
  currentEndDate: moment.Moment;
  currentStartDateTimestamp: string;
  currentEndDateTimestamp: string;
  currentIncrementStartStepChange: StepChange[];
  currentIncrementEndStepChange: StepChange[];
  currentDecrementStartStepChange: StepChange[];
  currentDecrementEndStepChange: StepChange[];
  electedPayPeriod: 'WEEKLY' | 'BI_WEEKLY' | 'SEMI_MONTHLY';
  semiMonthlyFirstHalfState: boolean;
}

const determinePayPeriods = (companyData) => {
  const results: Partial<CalculatedPayPeriod> = {};
  const today = moment();

  const electedPayPeriod = companyData?.payPeriod || 'WEEKLY';

  // not ideal - large switch statement
  // essentially CalculatedPayPeriod(data)
  switch (electedPayPeriod) {
    case 'SEMI_MONTHLY':
      // STEP CHANGE - governs next and previous operations for date manipulation
      const semiMonthlyIncrementStartStepChange: StepChange[] = [
        { operation: 'add', amount: 1, unit: 'second' },
      ];
      const semiMonthlyIncrementEndStepChange: StepChange[] = [
        { operation: 'add', amount: 1, unit: 'month' },
        { operation: 'subtract', amount: 1, unit: 'second' },
      ];
      const semiMonthlyDecrementStartStepChange: StepChange[] = [
        { operation: 'subtract', amount: 1, unit: 'month' },
        { operation: 'add', amount: 1, unit: 'second' },
      ];
      const semiMonthlyDecrementEndStepChange: StepChange[] = [
        { operation: 'subtract', amount: 1, unit: 'second' },
      ];

      // need firstPayPeriodStart and secondPayPeriodStart
      // otherwise default these to 1 and 16
      const firstHalfPayPeriodDay = companyData?.firstPayPeriodStart || 1;
      const secondHalfPayPeriodDay = companyData?.secondPayPeriodStart || 16;

      const semiMonthlyPayPeriod = localPayPeriodIntervalForSemiMonthly({
        firstPayPeriod: firstHalfPayPeriodDay,
        secondPayPeriod: secondHalfPayPeriodDay,
      });

      // take today's date and determine if they are in first half or second half
      const firstHalfBounds = [
        `${today.year()}-${today.month() + 1}-${firstHalfPayPeriodDay}`,
        `${today.year()}-${today.month() + 1}-${secondHalfPayPeriodDay}`,
      ];

      // is today between the first pay period and second pay period?
      const isInFirstHalfPayPeriod = today.isBetween(
        firstHalfBounds[0],
        firstHalfBounds[1],
        undefined,
        '[)'
      );

      if (isInFirstHalfPayPeriod) {
        results.currentStartDate = semiMonthlyPayPeriod.start;
        results.currentEndDate = semiMonthlyPayPeriod.end;
        // format('x) - transformation to timestamp
        results.currentStartDateTimestamp =
          semiMonthlyPayPeriod.start.format('x');
        // format('x) - transformation to timestamp
        results.currentEndDateTimestamp = semiMonthlyPayPeriod.end.format('x');
        results.semiMonthlyFirstHalfState = true;
      } else {
        results.currentStartDate = semiMonthlyPayPeriod.start;
        results.currentEndDate = semiMonthlyPayPeriod.end;
        // format('x) - transformation to timestamp
        results.currentStartDateTimestamp =
          semiMonthlyPayPeriod.start.format('x');
        // format('x) - transformation to timestamp
        results.currentEndDateTimestamp = semiMonthlyPayPeriod.end.format('x');
        results.semiMonthlyFirstHalfState = false;
      }
      results.currentIncrementStartStepChange =
        semiMonthlyIncrementStartStepChange;
      results.currentIncrementEndStepChange = semiMonthlyIncrementEndStepChange;
      results.currentDecrementStartStepChange =
        semiMonthlyDecrementStartStepChange;
      results.currentDecrementEndStepChange = semiMonthlyDecrementEndStepChange;
      results.electedPayPeriod = electedPayPeriod;
      return results;

    case 'BI_WEEKLY':
      // STEP CHANGE - governs next and previous operations for date manipulation
      const biWeeklyIncrementStartStepChange: StepChange[] = [
        { operation: 'add', amount: 14, unit: 'day' },
      ];
      const biWeeklyDecrementStartStepChange: StepChange[] = [
        { operation: 'subtract', amount: 14, unit: 'day' },
      ];

      const electedBiWeeklyStartDate =
        companyData?.biweeklyStartDate || new Date();
      const momentElectedBiWeeklyStartDate = moment(
        electedBiWeeklyStartDate
      ).startOf('day');

      const differenceInDays = today.diff(
        momentElectedBiWeeklyStartDate,
        'days'
      );

      const payPeriodsSinceBiWeeklyStartDate =
        differenceInDays / biWeeklyIncrementStartStepChange[0].amount;

      // algorithimically, for bi_weekly or 14 day step changes, multiply by payPeriodsSinceBiWeeklyStartDate (floored - take lower bound)
      const clonedMomentElectedBiWeeklyStartDate =
        momentElectedBiWeeklyStartDate.clone();
      // loop through biWeeklyIncrementStartStepChange
      biWeeklyIncrementStartStepChange.forEach((stepChange) => {
        clonedMomentElectedBiWeeklyStartDate[stepChange.operation](
          stepChange.amount * Math.floor(payPeriodsSinceBiWeeklyStartDate),
          stepChange.unit
        );
      });

      results.currentStartDate = clonedMomentElectedBiWeeklyStartDate;

      const anotherClonedMomentElectedBiWeeklyStartDate =
        momentElectedBiWeeklyStartDate.clone();
      // loop through biWeeklyIncrementStartStepChange
      biWeeklyIncrementStartStepChange.forEach((stepChange) => {
        anotherClonedMomentElectedBiWeeklyStartDate[stepChange.operation](
          stepChange.amount *
            (Math.floor(payPeriodsSinceBiWeeklyStartDate) + 1),
          stepChange.unit
        );
      });

      results.currentEndDate =
        anotherClonedMomentElectedBiWeeklyStartDate.subtract(1, 'millisecond');
      results.currentIncrementStartStepChange =
        biWeeklyIncrementStartStepChange;
      results.currentIncrementEndStepChange = biWeeklyIncrementStartStepChange;
      results.currentDecrementStartStepChange =
        biWeeklyDecrementStartStepChange;
      results.currentDecrementEndStepChange = biWeeklyDecrementStartStepChange;
      results.currentStartDateTimestamp =
        clonedMomentElectedBiWeeklyStartDate.format('x');
      results.currentEndDateTimestamp =
        anotherClonedMomentElectedBiWeeklyStartDate.format('x');
      results.electedPayPeriod = electedPayPeriod;

      return results;

    case 'WEEKLY':
    default:
      // STEP CHANGE - governs next and previous operations for date manipulation
      const weeklyIncrementStartStepChange: StepChange[] = [
        { operation: 'add', amount: 7, unit: 'day' },
      ];
      const weeklyDecrementStartStepChange: StepChange[] = [
        { operation: 'subtract', amount: 7, unit: 'day' },
      ];

      // default to MONDAY if no company.weekStartDay
      const electedWeekStartDate = companyData?.weekStartDay || 'MONDAY';
      // determine the iso weekday number for the start date
      const currentIsoElectedWeekStartDate = moment()
        .isoWeekday(electedWeekStartDate)
        .startOf('day');
      const momentElectedWeekStartDate = currentIsoElectedWeekStartDate;
      const clonedMomentElectedWeekStartDate =
        momentElectedWeekStartDate.clone();
      // clonedMomentElectedWeekStartDate used to add 7 days (immutable)

      // it should be looping through weeklyIncrementStartStepChange
      weeklyIncrementStartStepChange.forEach((stepChange) => {
        clonedMomentElectedWeekStartDate[stepChange.operation](
          stepChange.amount,
          stepChange.unit
        );
      });
      // after manipulation set the date to momentElectedWeekEndDate
      const momentElectedWeekEndDate = clonedMomentElectedWeekStartDate;

      // check if today is between momentElectedWeekStartDate and momenetElectedWeekEndDate
      const electedWeekRange = moment.range(
        momentElectedWeekStartDate,
        momentElectedWeekEndDate
      );

      if (today.within(electedWeekRange)) {
        results.currentStartDate = momentElectedWeekStartDate;
        results.currentEndDate = momentElectedWeekEndDate
          .subtract(1, 'day')
          .endOf('day');
        results.currentIncrementStartStepChange =
          weeklyIncrementStartStepChange;
        results.currentIncrementEndStepChange = weeklyIncrementStartStepChange;
        results.currentDecrementStartStepChange =
          weeklyDecrementStartStepChange;
        results.currentDecrementEndStepChange = weeklyDecrementStartStepChange;
        results.currentStartDateTimestamp =
          momentElectedWeekStartDate.format('x');
        results.currentEndDateTimestamp = momentElectedWeekEndDate.format('x');
        results.electedPayPeriod = electedPayPeriod;
      } else {
        // not within the current week so subtract a week from start and end
        const secondClonedMomentElectedWeekStartDate =
          momentElectedWeekStartDate.clone();
        // we clone this for start to use to calculate end date which is just minus a day
        const thirdClonedMomenetElectedWeekStartDate =
          momentElectedWeekStartDate.clone();

        // set
        // loop through weeklyDecrementStartStepChange to mutate secondClonedMomentElectedWeekStartDate
        weeklyDecrementStartStepChange.forEach((stepChange) => {
          secondClonedMomentElectedWeekStartDate[stepChange.operation](
            stepChange.amount,
            stepChange.unit
          );
        });

        results.currentStartDate = secondClonedMomentElectedWeekStartDate;
        results.currentEndDate = thirdClonedMomenetElectedWeekStartDate
          .subtract(1, 'day')
          .endOf('day');
        results.currentIncrementStartStepChange =
          weeklyIncrementStartStepChange;
        results.currentIncrementEndStepChange = weeklyIncrementStartStepChange;
        results.currentDecrementStartStepChange =
          weeklyDecrementStartStepChange;
        results.currentDecrementEndStepChange = weeklyDecrementStartStepChange;
        results.currentStartDateTimestamp =
          secondClonedMomentElectedWeekStartDate.format('x');
        results.currentEndDateTimestamp =
          thirdClonedMomenetElectedWeekStartDate.format('x');
        results.electedPayPeriod = electedPayPeriod;
      }

      return results;
  }
};

// Pay Period Context
const payPeriodSelectionContext = createContext({});

const { Provider } = payPeriodSelectionContext;

export function PayPeriodSelectionProvider(props: {
  children: ReactNode;
}): JSX.Element {
  const payPeriodSelection = usePayPeriodSelectionProvider();
  return <Provider value={payPeriodSelection}>{props.children}</Provider>;
}

export const usePayPeriodSelection: any = () => {
  return useContext(payPeriodSelectionContext);
};

const usePayPeriodSelectionProvider = () => {
  const { company }: { company: Company } = useCompany();
  const [payPeriodData, setPayPeriodData] = useState<
    Partial<CalculatedPayPeriod>
  >({});

  const [electedPayPeriod, setElectedPayPeriod] = useState('WEEKLY');
  const [currentStartDate, setCurrentStartDate] = useState<moment.Moment>(
    payPeriodData?.currentStartDate
  );
  const [currentEndDate, setCurrentEndDate] = useState<moment.Moment>(
    payPeriodData?.currentEndDate
  );
  const [currentStartDateTimestamp, setCurrentStartDateTimestamp] =
    useState<string>(payPeriodData?.currentStartDateTimestamp);
  const [currentEndDateTimestamp, setCurrentEndDateTimestamp] =
    useState<string>(payPeriodData?.currentEndDateTimestamp);

  // need to keep track of semi_monthly state - is current date range in first half or second half
  const [semiMonthlyFirstHalf, setSemiMonthlyFirstHalf] = useState(true);
  const [semiMonthlyFirstPayPeriodDay, setSemiMonthlyFirstPayPeriodDay] =
    useState(1);
  const [semiMonthlySecondPayPeriodDay, setSemiMonthlySecondPayPeriodDay] =
    useState(16);

  const [currentIncrementStartStepChange, setCurrentIncrementStartStepChange] =
    useState<StepChange[]>([
      {
        operation: 'add',
        amount: 7,
        unit: 'day',
      },
    ]);

  const [currentIncrementEndStepChange, setCurrentIncrementEndStepChange] =
    useState<StepChange[]>([
      {
        operation: 'add',
        amount: 7,
        unit: 'day',
      },
    ]);

  const [currentDecrementStartStepChange, setCurrentDecrementStartStepChange] =
    useState<StepChange[]>([
      {
        operation: 'subtract',
        amount: 7,
        unit: 'day',
      },
    ]);

  const [currentDecrementEndStepChange, setCurrentDecrementEndStepChange] =
    useState<StepChange[]>([
      {
        operation: 'subtract',
        amount: 7,
        unit: 'day',
      },
    ]);

  useEffect(() => {
    if (company) {
      const calcPayPeriodData = determinePayPeriods(company);

      // set
      setPayPeriodData(calcPayPeriodData);
      setElectedPayPeriod(calcPayPeriodData.electedPayPeriod);
      setCurrentStartDate(calcPayPeriodData.currentStartDate);
      setCurrentEndDate(calcPayPeriodData.currentEndDate);
      setCurrentStartDateTimestamp(calcPayPeriodData.currentStartDateTimestamp);
      setCurrentEndDateTimestamp(calcPayPeriodData.currentEndDateTimestamp);
      setCurrentIncrementStartStepChange(
        calcPayPeriodData.currentIncrementStartStepChange
      );
      setCurrentIncrementEndStepChange(
        calcPayPeriodData.currentIncrementEndStepChange
      );
      setCurrentDecrementStartStepChange(
        calcPayPeriodData.currentDecrementStartStepChange
      );
      setCurrentDecrementEndStepChange(
        calcPayPeriodData.currentDecrementEndStepChange
      );
      setSemiMonthlyFirstHalf(calcPayPeriodData?.semiMonthlyFirstHalfState);
      setSemiMonthlyFirstPayPeriodDay(company?.firstPayPeriodStart || 1);
      setSemiMonthlySecondPayPeriodDay(company?.secondPayPeriodStart || 16);
    }
  }, [company]);

  return {
    currentStartDate,
    setCurrentStartDate,
    currentEndDate,
    setCurrentEndDate,
    currentStartDateTimestamp,
    setCurrentStartDateTimestamp,
    currentEndDateTimestamp,
    setCurrentEndDateTimestamp,
    currentIncrementStartStepChange,
    currentIncrementEndStepChange,
    currentDecrementStartStepChange,
    currentDecrementEndStepChange,
    electedPayPeriod,
    semiMonthlyFirstHalf,
    setSemiMonthlyFirstHalf,
    semiMonthlyFirstPayPeriodDay,
    semiMonthlySecondPayPeriodDay,
  };
};
