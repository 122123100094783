export const colorList = [
  '#4682B4', // Steel Blue
  '#CB4154', // Brick Red
  '#008080', // Teal
  '#6B8E23', // Olive Drab
  '#E97451', // Burnt Sienna
  '#5F9EA0', // Cadet Blue
  '#BC8F8F', // Rosy Brown
  '#708090', // Slate Gray
  '#9370DB', // Medium Purple
  '#CD5C5C', // Indian Red
];
