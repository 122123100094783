import { Fragment } from 'react';
import Toast from 'components/shared/Toast';
import { Toast as IToast } from 'interfaces/toast';
import { create } from 'zustand';

interface ToastStore {
  toasts: IToast[];
}

const useToastStore = create<ToastStore>(() => ({
  toasts: [],
}));

export const ToastProvider = ({ children }) => {
  const { toasts } = useToastStore();

  return (
    <Fragment>
      <div className="fixed top-0 right-0 z-50 w-full max-w-sm">
        {toasts.map((toast, i) => (
          <Toast toast={toast} key={i} />
        ))}
      </div>
      {children}
    </Fragment>
  );
};

let id = 1;

export const addToast = (toast: Omit<IToast, 'id'>) => {
  useToastStore.setState((state) => ({
    toasts: [...state.toasts, { id: id++, ...toast }],
  }));
};

export const removeToast = (id: number) => {
  useToastStore.setState((state) => ({
    toasts: state.toasts.filter((t) => t.id !== id),
  }));
};

export const useToast = () => {
  return {
    addToast,
    removeToast,
  };
};
