import { createContext, useContext, useState, useEffect } from 'react';

import { useAuth } from 'hooks/useAuth';

import { AWSSecurityToken } from 'interfaces/sts';
import { getSts } from 'services/sts';
import { logError, showErrorToast } from 'utils/errorHandling';

const awsStsContext = createContext({});
const { Provider } = awsStsContext;

export function AwsStsProvider(props) {
  const awsSts = useAwsStsProvider();
  return <Provider value={awsSts}>{props.children}</Provider>;
}

export const useAwsSts: any = () => {
  return useContext(awsStsContext);
};

const useAwsStsProvider = () => {
  const auth = useAuth();
  const [stsCredentials, setStsCredentials] = useState<AWSSecurityToken>(null);

  const getStsCredentials = async (orgId: string) => {
    try {
      const res = await getSts({ organizationId: orgId });
      if (typeof res === 'object' && res.hasOwnProperty('err')) {
        throw new Error((res as any).err);
      } else {
        setStsCredentials(res as AWSSecurityToken);
      }
      return res;
    } catch (err) {
      console.error(`Error fetching STS credentials: ${err}`);
      logError(err);
    }
  };

  useEffect(() => {
    const isTokenExpired = () => {
      if (!stsCredentials || !stsCredentials.Expiration) {
        return true;
      }

      const currentTime = new Date();
      const expirationTime = new Date(stsCredentials.Expiration);
      return currentTime >= expirationTime;
    };

    if (isTokenExpired() && auth.user?.companyId) {
      getStsCredentials(auth.user?.companyId)
        .then((res) => {
          setStsCredentials(res as AWSSecurityToken);
        })
        .catch((err) => {
          logError(err);
          showErrorToast(err);
        });
    }
  }, [stsCredentials, auth.user?.companyId]);

  return {
    stsCredentials,
    getStsCredentials,
  };
};
