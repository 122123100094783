import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { getToken } from 'services/sendbird';

export const SENDBIRD_SESSION_KEY = 'sendbird_session';

export function useSendbirdSession() {
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [expiresAt, setExpiresAt] = useState<number | null>(null);

  const { user } = useAuth();

  useEffect(() => {
    let cachedSession = JSON.parse(
      localStorage.getItem(SENDBIRD_SESSION_KEY) ?? null
    );

    if (cachedSession && cachedSession.expires_at < Date.now()) {
      // expired token
      localStorage.removeItem(SENDBIRD_SESSION_KEY);
      cachedSession = null;
    }

    if (cachedSession) {
      // use cached token
      setToken(cachedSession.token);
      setExpiresAt(cachedSession.expires_at);
      setLoading(false);
    } else {
      // get new token
      getToken().then((data) => {
        localStorage.setItem(SENDBIRD_SESSION_KEY, JSON.stringify(data));
        setToken(data.token);
        setExpiresAt(data.expires_at);
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!expiresAt) return;

    const duration = Math.max(0, expiresAt - Date.now() - 1000 * 60); // 1 minute before expiration

    const MAX_DURATION = 1000 * 60 * 60 * 24 * 7; // 7 days

    if (duration > MAX_DURATION) {
      // refresh token after 7 days
      return;
    }

    const timeout = setTimeout(() => {
      // refresh token
      getToken().then((data) => {
        localStorage.setItem(SENDBIRD_SESSION_KEY, JSON.stringify(data));
        setToken(data.token);
        setExpiresAt(data.expires_at);
      });
    }, duration);

    return () => clearTimeout(timeout);
  }, [token, expiresAt]);

  return { token, userId: user?.uid, loading };
}
