import { defaultHammrHeaders } from 'utils/requestHelpers';

import { AWSSecurityToken } from 'interfaces/sts';
import {
  handleNetworkError,
  logError,
  validateNetworkResponse,
} from 'utils/errorHandling';

export const getSts = async (
  urlParamsObj: Record<string, string>
): Promise<AWSSecurityToken | { err: any }> => {
  const transformedUrlParams = new URLSearchParams(urlParamsObj).toString();
  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/sts-token?${transformedUrlParams}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          ...defaultHammrHeaders,
        },
      }
    );

    await validateNetworkResponse(res);

    const json = await res.json();
    return json;
  } catch (err) {
    console.log(`Error fetching sts: ${err}`);
    logError(err);
    handleNetworkError(err);
  }
};
