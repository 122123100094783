import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react';

import moment from 'moment';

export type DateFilter =
  | 'PAY_PERIOD'
  | 'DAILY'
  | 'WEEKLY'
  | 'MONTHLY'
  | 'CUSTOM';

import { StepChange } from 'hooks/usePayPeriodSelection';

// Date Selection Context
const dateSelectionContext = createContext<any>(null);

const { Provider } = dateSelectionContext;

export function DateSelectionProvider({ children }: { children: ReactNode }) {
  const dateSelection = useDateSelectionProvider();
  return <Provider value={dateSelection}>{children}</Provider>;
}

export function useDateSelection() {
  const context = useContext(dateSelectionContext);

  if (!context) {
    throw new Error(
      'useDateSelection must be used within a DateSelectionProvider'
    );
  }

  return context;
}

export const filteredDateChangeMappings = (operation: string) => {
  const map: Record<string, StepChange[]> = {
    PREV_DAILY: [{ operation: 'subtract', amount: 1, unit: 'day' }],
    NEXT_DAILY: [{ operation: 'add', amount: 1, unit: 'day' }],
    PREV_WEEKLY: [{ operation: 'subtract', amount: 1, unit: 'week' }],
    NEXT_WEEKLY: [{ operation: 'add', amount: 1, unit: 'week' }],
    PREV_MONTHLY: [{ operation: 'subtract', amount: 1, unit: 'month' }],
    NEXT_MONTHLY: [{ operation: 'add', amount: 1, unit: 'month' }],
  };

  return map[operation];
};

// the gist of this is yield to usePayPeriodSelection if PAY_PERIOD is selected, otherwise use this
const useDateSelectionProvider = () => {
  // by default the currentStartDateSelected should be set to the pay period dates from usePayPeriodSelection
  const [currentSelectedDateFilter, setCurrentSelectedDateFilter] =
    useState<DateFilter>('PAY_PERIOD');
  const [currentStartDateSelected, setCurrentStartDateSelected] =
    useState<moment.Moment>(moment());
  const [currentEndDateSelected, setCurrentEndDateSelected] =
    useState<moment.Moment>(moment());

  // function to determine date filter
  const registerDatesByFilter = useCallback(
    (
      filterSelected: DateFilter,
      dateInputs?: { startDate: moment.Moment; endDate: moment.Moment }
    ) => {
      const currentDate = moment();

      switch (filterSelected) {
        case 'PAY_PERIOD':
          setCurrentStartDateSelected(dateInputs.startDate);
          setCurrentEndDateSelected(dateInputs.endDate);
          return {
            startDate: dateInputs ? dateInputs.startDate : null, // pass in the start date from usePayPeriodSelection
            endDate: dateInputs ? dateInputs.endDate : null, // pass the end date from usePayPeriodSelection
          };
        case 'DAILY':
          const updatedStartDate = currentDate.clone().startOf('day');
          const updatedEndDate = currentDate.clone().endOf('day');
          setCurrentStartDateSelected(updatedStartDate);
          setCurrentEndDateSelected(updatedEndDate);
          return {
            startDate: updatedStartDate,
            endDate: updatedEndDate,
          };
        case 'WEEKLY':
          const updatedStartDateWeekly = currentDate.clone().startOf('isoWeek');
          const updatedEndDateWeekly = currentDate.clone().endOf('isoWeek');
          setCurrentStartDateSelected(updatedStartDateWeekly);
          setCurrentEndDateSelected(updatedEndDateWeekly);
          return {
            startDate: updatedStartDateWeekly,
            endDate: updatedEndDateWeekly,
          };
        case 'MONTHLY':
          const updatedStartDateMonthly = currentDate.clone().startOf('month');
          const updatedEndDateMonthly = currentDate.clone().endOf('month');
          setCurrentStartDateSelected(updatedStartDateMonthly);
          setCurrentEndDateSelected(updatedEndDateMonthly);
          return {
            startDate: updatedStartDateMonthly,
            endDate: updatedEndDateMonthly,
          };
        case 'CUSTOM':
          setCurrentStartDateSelected(dateInputs.startDate);
          setCurrentEndDateSelected(dateInputs.endDate);
          return {
            startDate: dateInputs ? dateInputs.startDate.startOf('day') : null,
            endDate: dateInputs ? dateInputs.endDate.endOf('day') : null,
          };
      }
    },
    []
  );

  return {
    currentStartDateSelected,
    setCurrentStartDateSelected,
    currentEndDateSelected,
    setCurrentEndDateSelected,
    currentSelectedDateFilter,
    setCurrentSelectedDateFilter,
    registerDatesByFilter,
  };
};
