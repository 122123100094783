import moment from 'moment';
import { range } from 'lodash';

export const isNowBetweenDates = (date1: Date, date2: Date): boolean => {
  const now = new Date();
  return date1 <= now && now <= date2;
};

export const isItemActive = (startDateStr: string, endDateStr: string) => {
  if (startDateStr && endDateStr) {
    const momentStartDate = moment(startDateStr);
    const momentEndDate = moment(endDateStr);
    return isNowBetweenDates(momentStartDate.toDate(), momentEndDate.toDate());
  } else if (startDateStr) {
    const momentStartDate = moment(startDateStr);
    return momentStartDate.toDate() <= new Date();
  } else {
    return false;
  }
};

export const formatLocaleUsa = (date: Date | string) => {
  if (!date) return;
  const momentDate = moment(date).format('M/DD/YYYY');

  return momentDate;
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString.replaceAll('-', '/'));

  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const dateOf = (date.getDate() < 10 ? '0' : '') + date.getDate();

  return days[date.getDay()] + ', ' + months[date.getMonth()] + ' ' + dateOf;
};

export const formatDateToCheckFormat = (date: Date): string => {
  const d = date;
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const getCurrentDate = (): string => {
  const pacificTime = new Date().toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
  });
  return moment(pacificTime).format('YYYY-MM-DD');
};

export const addWeekdays = (date, days) => {
  date = moment(date);

  while (days > 0) {
    date = date.add(1, 'days');
    // decrease "days" only if it's a weekday.
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1;
    }
  }
  return date;
};

export const isDayTheWeekend = (date: moment.Moment) => {
  const day = date.day();
  return day === 0 || day === 6;
};

// dateStr should be: {date.year}-{date.month}-{date.day}
// timeStr should be: {time.hour}:{time.minute}:00
export const getTimestampFromDateParts = (
  dateStr: string,
  timeStr: string,
  isNightShifted: boolean
): moment.Moment => {
  const parsedTimeStr = timeStr.split(':');
  let dateStrToUse = dateStr;
  if (isNightShifted) {
    dateStrToUse = moment(dateStr).add(1, 'days').format('YYYY-MM-DD');
  }
  if (dateStr && timeStr && parsedTimeStr.length === 3) {
    return moment(new Date(`${dateStrToUse} ${timeStr}`));
  }

  throw new Error('Invalid date or time string');
};

export const labelDate = (inputDate: moment.Moment) => {
  const today = moment().startOf('day');
  const tomorrow = moment().add(1, 'days').startOf('day');
  const dateToCheck = moment(inputDate).startOf('day');

  if (dateToCheck.isSame(today)) {
    return inputDate.format('h:mm a [today]');
  } else if (dateToCheck.isSame(tomorrow)) {
    return inputDate.format('h:mm a [tomorrow]');
  } else {
    return inputDate.format('h:mm a, MMM Do, YYYY');
  }
};

export const roundedUpTimestampToUse = (
  notificationTimestamp: moment.Moment
) => {
  return notificationTimestamp.second() || notificationTimestamp.millisecond()
    ? notificationTimestamp.add(1, 'minute').startOf('minute')
    : notificationTimestamp.startOf('minute');
};

export const minutesAfterMidnight = (time: moment.Moment) => {
  return time.minutes() + time.hours() * 60;
};

export const isWeekend = (date: Date | moment.Moment) => {
  const momentDate = moment(date);
  const day = momentDate.day();
  // does day of the week equal saturday (6) or sunday (0)?
  return day === 0 || day === 6;
};

interface SemiMonthlyPayPeriod {
  firstPayPeriod: number;
  secondPayPeriod: number;
}

function containsValue(data, value) {
  // Check if any sub-array contains the value
  return data.some((subArray) => subArray.includes(value));
}

export const localPayPeriodIntervalForSemiMonthly = (
  startDays: SemiMonthlyPayPeriod,
  forDate: moment.Moment = moment()
): { start: moment.Moment; end: moment.Moment } => {
  let firstRanges: number[][] = [];
  let secondRanges: number[][] = [];

  const daysInCurrentMonth = forDate.daysInMonth();

  if (startDays.firstPayPeriod < startDays.secondPayPeriod) {
    const curatedSecondPayPeriod = Math.min(
      startDays.secondPayPeriod,
      daysInCurrentMonth
    );

    firstRanges = [range(startDays.firstPayPeriod, curatedSecondPayPeriod)];
    if (curatedSecondPayPeriod <= daysInCurrentMonth) {
      secondRanges = [range(curatedSecondPayPeriod, daysInCurrentMonth + 1)];
    }

    if (startDays.firstPayPeriod > 1) {
      secondRanges.push(range(1, startDays.firstPayPeriod));
    }
  } else {
    const curatedFirstPayPeriod = Math.min(
      startDays.firstPayPeriod,
      daysInCurrentMonth
    );
    if (curatedFirstPayPeriod <= daysInCurrentMonth) {
      firstRanges = [range(curatedFirstPayPeriod, daysInCurrentMonth - 1)];
    }

    if (startDays.secondPayPeriod > 1) {
      firstRanges.push(range(1, startDays.secondPayPeriod));
    }

    secondRanges = [range(startDays.secondPayPeriod, curatedFirstPayPeriod)];
  }

  const theDay = forDate.date();
  let desiredRanges: number[][] | undefined;
  let monthAdjustmentData: { index: number; adjustment: number } | undefined;

  const dayInFirstRangeIndex = containsValue(firstRanges, theDay);
  const indexOfDayInFIrstRange = firstRanges.findIndex((range) =>
    range.includes(theDay)
  );

  const dayInSecondRangeIndex = containsValue(secondRanges, theDay);
  const indexOfDayInSecondRange = secondRanges.findIndex((range) =>
    range.includes(theDay)
  );

  if (dayInFirstRangeIndex) {
    desiredRanges = firstRanges;
    if (firstRanges.length > 1) {
      if (indexOfDayInFIrstRange === 0) {
        monthAdjustmentData = { index: 1, adjustment: 1 };
      } else if (indexOfDayInFIrstRange === 1) {
        monthAdjustmentData = { index: 0, adjustment: -1 };
      }
    }
  }

  if (dayInSecondRangeIndex) {
    desiredRanges = secondRanges;
    if (secondRanges.length > 1) {
      if (indexOfDayInSecondRange === 0) {
        monthAdjustmentData = { index: 0, adjustment: 1 };
      } else if (indexOfDayInSecondRange === 1) {
        monthAdjustmentData = { index: 1, adjustment: -1 };
      }
    }
  }

  if (!desiredRanges || desiredRanges?.length === 0) {
    return { start: forDate, end: forDate.add(7, 'days') }; // Fallback object to prevent logic errors
  }

  let start = forDate.clone().set({
    date: desiredRanges[0][0],
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const lastRange = desiredRanges[desiredRanges.length - 1];
  let end = forDate.clone().set({ date: lastRange[lastRange.length - 1] });

  if (monthAdjustmentData) {
    if (monthAdjustmentData.index === 1) {
      start = start.add(monthAdjustmentData.adjustment, 'months');
      if (
        monthAdjustmentData.adjustment === -1 &&
        (startDays.firstPayPeriod === 31 || startDays.secondPayPeriod === 31)
      ) {
        start = start.endOf('month').startOf('day');
      }
    } else if (monthAdjustmentData.index === 0) {
      end = end.add(monthAdjustmentData.adjustment, 'months');
    }
  }

  end = end.endOf('day');

  if (start.isBefore(end)) {
    return { start: start, end: end };
  } else {
    console.error(
      'Invalid Range: Error handling or fallback can be implemented here.'
    );
    return { start: end, end: start }; // Fallback object to prevent logic errors
  }
};
