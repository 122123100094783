import { AppProps } from 'next/app';

import { AuthProvider } from 'hooks/useAuth';
import { CompanyProvider } from 'hooks/useCompany';
import { ToastProvider } from 'hooks/useToast';
import { AwsStsProvider } from 'hooks/useAwsSts';
import { AwsS3Provider } from 'hooks/useAwsS3';
import { PayPeriodSelectionProvider } from 'hooks/usePayPeriodSelection';
import { DateSelectionProvider } from 'hooks/useDateSelection';
import 'css/tailwind.css';
import 'css/fonts.css';
import 'css/theme.css';
import 'css/fullcalendar.css';
import 'css/phoneinput.css';
import 'css/third-party.css';

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <AuthProvider>
      <CompanyProvider>
        <ToastProvider>
          <AwsStsProvider>
            <AwsS3Provider>
              <DateSelectionProvider>
                <PayPeriodSelectionProvider>
                  <Component {...pageProps} />
                </PayPeriodSelectionProvider>
              </DateSelectionProvider>
            </AwsS3Provider>
          </AwsStsProvider>
        </ToastProvider>
      </CompanyProvider>
    </AuthProvider>
  );
}
