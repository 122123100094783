import { CheckCompany } from 'interfaces/company';
import {
  logError,
  validateNetworkResponse,
  handleNetworkError,
} from 'utils/errorHandling';
import { defaultHammrHeaders } from 'utils/requestHelpers';

export const getHammrOrganization = async (companyId: string) => {
  try {
    // parse uid to be integer - what backend expects
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/organizations/${companyId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          ...defaultHammrHeaders,
        },
      }
    );

    await validateNetworkResponse(res);

    const json = await res.json();
    return json.data;
  } catch (err) {
    console.log(`Error fetching organization: ${err}`);
    logError(err);
    handleNetworkError(err);
  }
};

export const updateHammrOrganization = async (id: string, data: any) => {
  try {
    // parse uid to be integer - what backend expects
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1/organizations/${id}`,
      {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          ...defaultHammrHeaders,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );

    await validateNetworkResponse(res);

    const json = await res.json();
    return json.data;
  } catch (err) {
    console.log(`Error updating organization: ${err}`);
    logError(err);
    handleNetworkError(err);
  }
};

//---------------------------Check company services------------------------------/

export const createCheckCompany = async (data: CheckCompany): Promise<any> => {
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      ...defaultHammrHeaders,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`/api/companies`, options);
  // await validateNetworkResponse(response); // custom error handling applied in component
  return response;
};

export const updateCheckCompany = async (
  id: string,
  data: CheckCompany
): Promise<any> => {
  const options: RequestInit = {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      ...defaultHammrHeaders,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`/api/companies/${id}`, options);
  await validateNetworkResponse(response);
  return response;
};

export const getCheckCompany = async (id: string): Promise<CheckCompany> => {
  const options: RequestInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...defaultHammrHeaders,
      'Access-Control-Allow-Origin': '*',
    },
  };

  const response = await fetch(`/api/companies/${id}`, options);
  await validateNetworkResponse(response);
  const data = await response.json();
  return data as CheckCompany;
};

export const generateCheckCompanyOnboardLink = async (
  companyId: string,
  signer_name: string,
  signer_title: string,
  email: string
): Promise<any> => {
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...defaultHammrHeaders,
    },
    body: JSON.stringify({ signer_name, signer_title, email }),
  };
  const response = await fetch(`/api/companies/${companyId}/onboard`, options);
  await validateNetworkResponse(response);
  return response.json();
};

export const generateCompanyPaymentSetupLink = async (
  companyId: string,
  signer_name: string,
  signer_title: string,
  email: string
): Promise<any> => {
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...defaultHammrHeaders,
    },
    body: JSON.stringify({ signer_name, signer_title, email }),
  };
  const response = await fetch(
    `/api/companies/${companyId}/components/payment_setup`,
    options
  );
  await validateNetworkResponse(response);
  return response.json();
};

export const generateRunPayrollComponentLink = async (
  companyId: string,
  payrollId?: string
): Promise<any> => {
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...defaultHammrHeaders,
    },
  };

  let url = `/api/companies/${companyId}/components/manual_payroll`;

  if (payrollId) {
    url += `?payroll=${payrollId}`;
  }

  const response = await fetch(url, options);
  await validateNetworkResponse(response);
  return response.json();
};

export const generateTaxSetupComponentLink = async (
  companyId: string,
  signer_name: string,
  signer_title: string,
  email: string
): Promise<any> => {
  const options: RequestInit = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...defaultHammrHeaders,
    },
    body: JSON.stringify({ signer_name, signer_title, email }),
  };
  const response = await fetch(
    `/api/companies/${companyId}/components/tax_setup`,
    options
  );
  await validateNetworkResponse(response);
  return response.json();
};

export const getPayrollJournal = async (
  companyId: string,
  startDate: string,
  endDate: string
): Promise<any> => {
  const options: RequestInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...defaultHammrHeaders,
      Accept: 'text/csv',
      'Access-Control-Allow-Origin': '*',
    },
  };
  const res = await fetch(
    `/api/companies/${companyId}/reports/payroll_journal?start=${startDate}&end=${endDate}&include_contractors=true`,
    options
  );

  await validateNetworkResponse(res);

  const blob = await res.blob();

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `payroll-journal_${startDate}_to_${endDate}.csv`;
  document.body.appendChild(a);
  a.click();
  a.remove();

  return true;
};

export const getPayrollSummary = async (
  companyId: string,
  startDate: string,
  endDate: string
): Promise<any> => {
  const options: RequestInit = {
    method: 'GET',
    credentials: 'include',
    headers: {
      ...defaultHammrHeaders,
      Accept: 'text/csv',
      'Access-Control-Allow-Origin': '*',
    },
  };
  const res = await fetch(
    `/api/companies/${companyId}/reports/payroll_summary?start=${startDate}&end=${endDate}&include_contractors=true`,
    options
  );
  await validateNetworkResponse(res);
  const blob = await res.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `payroll-summary_${startDate}_to_${endDate}.csv`;
  document.body.appendChild(a);
  a.click();
  a.remove();
  return true;
};
