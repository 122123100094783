import * as Sentry from '@sentry/nextjs';
import { addToast } from 'hooks/useToast';
import { AppNetworkError, isAppError, isHandledError } from './AppError';

type ApiError = {
  error: string;
};

let warned = false;
const DEFAULT_ERROR_TITLE = 'Something went wrong';

const formattedErrorMessage = (error: unknown) => {
  const errorMessage = error.hasOwnProperty('message')
    ? (error as { message: string }).message
    : '';

  const knownErrors = ['Internal server error'];
  let errMsg = '';

  if (errorMessage && !knownErrors.includes(errorMessage)) {
    errMsg = errorMessage;
  } else if (typeof error === 'string') {
    errMsg = error;
  }

  return errMsg;
};

export function showErrorToast(error: unknown, title?: string) {
  let errorMessage = formattedErrorMessage(error);
  const toastTitle = title || error['title'] || DEFAULT_ERROR_TITLE;

  // re-write errorMessage to 'Access Denied' if errorMessage includes 'User is not active' or 'Organization is not active'
  if (
    errorMessage.includes('User is not active') ||
    errorMessage.includes('Organization is not active')
  ) {
    errorMessage = 'Access Denied';
  }

  if (isAppError(error)) {
    return addToast({
      title: toastTitle,
      description: errorMessage,
      type: 'error',
    });
  }

  addToast({
    title: toastTitle,
    description: errorMessage,
    type: 'error',
  });
}

export function logError(error: unknown) {
  if (isHandledError(error)) {
    // already handled, no need to report sentry
    return;
  }

  if (process.env.NODE_ENV === 'production') {
    Sentry.captureException(error);
  } else if (!warned) {
    warned = true;
    console.warn(
      'Sentry reporting is disabled in development. To see Sentry reports, set the NODE_ENV environment variable to "production".'
    );
  }

  // for debugging purposes
  console.error(error);
}

export function handleNetworkError(error: unknown): never {
  if (isHandledError(error)) {
    throw error;
  }

  throw new AppNetworkError(error);
}

export async function validateNetworkResponse(
  response: Response
): Promise<Response> {
  if (response.ok) {
    // response is ok, no need to throw
    return response;
  }

  let json: any;

  if (response.headers.get('content-type')?.includes('application/json')) {
    try {
      // response is json, try to parse it
      json = await response.json();
    } catch (error) {
      // json parsing failed
    }
  }

  let internalMessage: string = response.statusText;

  if (response.headers.get('content-type')?.includes('text/html')) {
    const text = await response.text();
    internalMessage = text;
  }

  if (json?.error && typeof json.error === 'string') {
    internalMessage = json.error;
  } else if (
    json?.error &&
    json?.error.input_errors &&
    json?.error.input_errors[0] &&
    json?.error.input_errors[0].message
  ) {
    internalMessage = json.error.input_errors[0].message;
  }

  // universal redirect Check for specific 403 errors
  if (
    response.status === 403 &&
    (internalMessage === 'User is not active' ||
      internalMessage === 'Organization is not active')
  ) {
    // set localStorage key 'user' to 'false'
    localStorage.setItem('user', 'false');

    // redirect to /login
    window.location.href = '/login';
    // optionally return a resolved promise or another way to stop further processing
    return Promise.reject(new Error(`[${response.status}] ${internalMessage}`));
  }

  logError(new Error(`[${response.status}] ${internalMessage}`));

  let message = 'Something went wrong';

  if (
    response.status === 401 ||
    response.status === 400 ||
    response.status === 403
  ) {
    message = internalMessage;
  }

  throw new AppNetworkError(message);
}

export const setCheckSpecificErrorMessage = (error, setError) => {
  if (error?.type == 'address_invalid') {
    if (error.message.includes('postal code')) {
      setError('postalCode', {
        type: 'manual',
        message: 'Invalid postal code',
      });
    } else if (error.message.includes('Incorrect city')) {
      setError('city', {
        type: 'manual',
        message: 'Invalid city',
      });
    } else if (error.message.includes('Invalid premise number')) {
      setError('address', {
        type: 'manual',
        message: 'Invalid address',
      });
    } else {
      // if none of the custom checks caught this we're just going to tell the user the whole address field needs to be fixed
      setError('postalCode', {
        type: 'manual',
        message: 'Invalid postal code',
      });
      setError('city', {
        type: 'manual',
        message: 'Invalid city',
      });
      setError('address', {
        type: 'manual',
        message: 'Invalid address',
      });
      setError('state', {
        type: 'manual',
        message: 'Invalid address',
      });
    }
  } else if (error?.type == 'state_not_supported') {
    setError('state', {
      type: 'manual',
      message: error.message,
    });
  } else if (error.input_errors) {
    if (error.input_errors[0].field == 'state') {
      setError('state', {
        type: 'manual',
        message: error.input_errors[0].message,
      });
    }
  }
};

const setFormErrorMessage = (error, setError) => {
  // user error list
  const errorsList: {
    field: string;
    apiError: string;
    humanError: string;
  }[] = [
    {
      field: 'phone',
      apiError: 'The provided phone number is not valid',
      humanError: 'Enter a valid phone number',
    },
  ];

  const errMessage = error?.message || error;

  const errorMatch = errorsList.find((errorItem) =>
    errorItem.apiError.includes(errMessage)
  );

  if (errorMatch) {
    setError(errorMatch.field, {
      type: 'manual',
      message: errorMatch.humanError,
    });
  }
};

export const handleFormError = (err, setError) => {
  if (err?.type) {
    setCheckSpecificErrorMessage(err, setError);
  } else {
    setFormErrorMessage(err, setError);
  }
};
